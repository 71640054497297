@import '../../Styles/main.scss';

.page-init {
  .alert-disabled-user {
    margin-top: 1rem;
    width: 100%;
    height: 100px;
    background-color: #edecec;
    border-radius: 5px;
    border: 1px solid red;
    border-style: dashed;
    display: flex;
    align-items: center;

    .container {
      padding-left: 1rem;
      width: 100%;

      .title {
        width: 100%;
        display: flex;
        align-items: center;

        h4 {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }

      p {
        margin: 0 auto;
      }

      a {
        color: rgb(206, 137, 10);
        font-weight: 600;
      }

      a:hover {
        color: rgb(255, 176, 30);
        cursor: pointer;
        font-weight: 600;
      }
    }
  }

  & .news_banner {
    width: 100%;
    border: 1px solid black;
    height: 150px;
    background-color: orange;
    color: white;

    &-content {
      margin: 1rem;
    }
  }

  & .tiendas_destacadas,
  .clinicas_destacadas,
  .promos {
    display: flex;
    justify-content: space-around;
  }

  &__landing-page {
    // div {
    //   border: 1px solid black;
    // }

    & .section-image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    & .section-text {
      display: flex;
      justify-content: space-around;
      align-items: center;

      img {
        width: 20%;
      }
    }

    & .section-form {
      display: flex;
      justify-content: center;

      & .form {
        p {
          margin: 1rem 0 2rem 0;
        }

        & .input-email {
          display: flex;
          justify-content: center;

          #outlined-basic {
            padding: 10px 10px;
          }
        }


        & .btn {
          display: block;
          margin: 2rem auto;
          width: 50%;
        }
      }
    }

    & .section-rrss {
      display: flex;
      justify-content: space-around;
      width: 50%;
      margin: 1rem auto;

      & .rrss {
        img {
          width: 60px;
        }

        p {
          text-align: center;
        }
      }
    }

    & .section-footer {
      display: flex;
      justify-content: space-around;
    }
  }

  .header {
    font-family: 'Nunito';
  
    .appbar {
      height: 80px;
      background-color: $police-blue
    }
  }
  
  .banner {
    width: 100%;
  }
  
  .text {
    background-color: $medium-aqumarine;
    font-family: 'Nunito';
  
    .container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 200px;
  
      .parrafo {
        font-size: 20px;
      }
  
      .petInfo {
        width: 140px;
        height: 180px;
        position: relative;
  
        & img {
          // width: 50px;
          // height: 50px;
          // max-width: auto !important;
          // max-height: auto !important;
        }
      }
    }
  
  }
  
  .form {
    text-align: center;
    background-color: $orange-yellow;
    font-family: 'Nunito';
  
    .container {
      width: 50%;
      padding: 2rem 0;
      margin: 0 auto;
    }
  
    .input {
      background-color: white;
      border-radius: 6px;
    }
  
    .button {
      margin: 1rem 0;
  
      .btn {
        height: 50px;
      }
    }
  }
  
  .rrss {
    font-family: 'Nunito';
    padding: 2rem 0;
    width: 70%;
    margin: 0 auto;
    .title {
      text-align: center;
      padding: 1rem;
    }
  
    .container {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 1rem 0;
      margin: 0 auto;
  
      .container {
        width: 64px;
        height: 64px;
        position: relative;
  
        // margin: 1rem auto;
        & img {
          max-width: auto !important;
          max-height: auto !important;
          width: 75px;
        }
      }
    }
  
  }
  
  .contact {
    width: 100%;
    background-color: $police-blue;
    font-family: 'Nunito';
  
    color: $white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    & a{
      color: white;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 991px) {
  .page-init {
    // width: 95%;
    margin: 0 auto;

    .alert-disabled-user {
      //margin: .5rem;
      width: 100%;
      height: 100%;
      background-color: #edecec;
      border-radius: 5px;
      border: 1px solid red;
      border-style: dashed;
      display: flex;
      align-items: center;

      .container {
        padding-left: 1rem;
        width: 100%;

        .title {
          width: 100%;
          display: flex;
          align-items: center;

          h4 {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }

        p {
          margin: 0 auto;
        }

        a {
          color: rgb(206, 137, 10);
          font-weight: 600;
        }

        a:hover {
          color: rgb(255, 176, 30);
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    .header {
      width: 100%;
      border: 1px solid red;
      height: 10vh;
  
      .appbar {
        height: 100%;
        background-color: $police-blue
      }
    }
  
    .text {
      padding: 2rem;
  
      .container {
        display: block;
        margin: 2rem auto;
        & h2{
          font-size: 2rem;
        }
      }
  
  
      background-color: $medium-aqumarine;
    }
  
    .form {
      text-align: center;
      background-color: $orange-yellow;
  
      .container {
        width: 90%;
        padding: 2rem 0;
        margin: 0 auto;
      }
  
      .input {
        background-color: white;
        border-radius: 6px;
      }
  
      .button {
        margin: 2rem 0;
        width: 100%;
  
        .btn {
          height: 50px;
        }
      }
    }
    .rrss {
      font-family: 'Nunito';
      // padding: 2rem 0;
      width: 90%;
      // margin: 0 auto;
    }
  }

  // & .tiendas_destacadas {
  //   display: block !important;
  //   justify-content: space-around;
  // }

  // & .clinicas_destacadas {
  //   display: block !important;
  //   justify-content: space-around;
  // }

  // & .promos {
  //   display: block !important;
  //   justify-content: space-around;
  // }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .page-init {
    width: 100%;
    margin: 0 auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1441px) {
  .page-init {
    width: 60%;
    margin: 0 auto;
  }
}