@import "../Styles/main.scss";
.MuiAppBar-colorPrimary {
  background-color: $primary-dark !important;
  color: $primary !important;
}
.header_options {
  min-height: 30px;
  background-color: $primary !important;
  color: black !important;
  width: 100%;
  display: flex;
  align-items: center;
  .comuna {
    display: flex;
    align-items: center;
    input {
      height: 60%;
    }
  }
  .categorias {
    display: flex;
  }
}


.header-container {
  & .menu-button {
    margin-right: 1rem;
  }
  & .title {
    display: block;
    cursor: pointer;
  }
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 991px) {
  .header-container {
    & .menu-button {
      margin-right: 1rem;
    }
    & .button-user{
      margin-right: 1rem;
      font-size: 1rem;
      p{
        margin: 0;
        margin-left: 1rem;
      }
    }
    & .title {
     // display: none;
    }
  }
}
