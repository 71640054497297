//primarios

$oslo-gray:#939696;
$lunar-green:#3F403D;
$black:#000000;
$white:#FFFFFF;
$my-sin:#FFBB22;
$primary:#F2F2F2;
$secondary:#F2B366;
$secondary-dark:#D99E6A;
$primary-dark:#ffffff;
$brown:#8C5B3F;


$police-blue:#365B6D;
$sandy-brown: #F3985F;
$medium-aqumarine:#68CCBE;
$jelly-bean:#DD6553;
$orange-yellow:#F9C763;
// $white:#ffffff;
// $black:#000000;



$primary:white;
$primary-light:#c1d5e0;
$primary-dark:#62757f;

$secondary:#b0bec5;
$secondary-ligth:white;
$secondary-dark:#e2f1f8;

$primary-text:#000000;

@font-face {
    font-family: 'Nunito';
    src: url('./Nunito/Nunito-Regular.ttf');
}
@font-face {
    font-family: 'Nunito-Bold';
    src: url('./Nunito/Nunito-Bold.ttf');
}